(function ($) {

    $(function () {

        var filterData = {};

        $( '.gallery-filter-button').click(function() {

            var gallery_module_id = $(this).parent().parent().parent().parent().attr('data');
            var type = $(this).val();

            $('#gallery-images-default-' + gallery_module_id).hide();
            $('.gallery-filter-button').removeClass( 'gallery-category-active');
            $(this).addClass( 'gallery-category-active' );

            if( typeof type !== 'undefined' ) {
                filterData['type'] = type;
            }

            if( typeof gallery_module_id !== 'undefined' ) {
                filterData['gallery_module_id'] = gallery_module_id;
            }

            gallery_ajax_get ( type , gallery_module_id, filterData);

        });

       function gallery_ajax_get( type, gallery_module_id, filterData) {

            var gallery_id = gallery_module_id;
            var type = type;

            console.log(gallery_id + '1');

            $.ajax({
                type: 'POST',
                url: ajax_path.ajaxurl,
                security: ajax_path.ajaxnonce,
                data: {"action": 'load-gallery', type: type, gallery_module_id: gallery_module_id, filterData: filterData },
                success: function(response) {
                    $('#response-call-' + gallery_id).removeClass( 'show' );
                    $('#ajax-target-' + gallery_id).html(response);
                    $('#response-call-' + gallery_id).show();
                    $('#gallery-loading-' + gallery_id).css("display", "flex");

                    setTimeout(function() {
                        $('#gallery-loading-' + gallery_id).css("display", "none");
                    }, 500);

                    return false;
                }
            });

        }

       /* $('button.room-filter-button').click(function() {

            var room_button_id = $(this).attr('id');
            var type = $(this).val();

            console.log(room_button_id + type);

            $(".room-filter-button").removeClass( 'btn-current' );
            $(this).addClass( 'btn-current' );

            if( typeof room_button_id !== 'undefined' ) {
              filterData['room_button_id'] = room_button_id;
            }

            if( typeof type !== 'undefined' ) {
                filterData['type'] = type;
            }

            $('#room-type-filter').val(type);

            room_ajax_get ( room_button_id, type, filterData);

        });

       $('#room-filter-submit' ).click(function() {

            var room_button_id = '';
            var type = $("#room-type-filter").val();

            $(".room-filter-button").removeClass( 'btn-current' );
            $(".room-filter-filter-button-" + type).addClass('btn-current');

           if( typeof type !== 'undefined' ) {
             filterData['type'] = type;
           }

           if( typeof room_button_id !== 'undefined' ) {
                  filterData['room_button_id'] = room_button_id;
              }

            room_ajax_get ( type, room_button_id, filterData );

        });

       function room_ajax_get( type, room_button_id, filterData) {

            $.ajax({
                type: 'POST',
                url: ajax_path.ajaxurl,
                security: ajax_path.ajaxnonce,
                data: {"action": 'load-room', type: type, room_button_id: room_button_id, filterData: filterData },
                success: function(response) {
                    $('#response-call').removeClass( 'show' );
                    $('#ajax-target').html(response);
                    $('.post-type-archive-rooms #room-listing-container').hide();
                    $('#response-call').show();
                    $('.ajax-loading').css("display", "flex");

                    setTimeout(function(){
                        $('.ajax-loading').css("display", "none");
                        $( '#response-call' ).addClass( 'show' );
                    }, 500);

                    return false;
                }
            });
        } */

    });

})(jQuery, this);